import React from 'react';
import Team from './Team'
import './TeamList.css';
import ColorLegend from '../planning/ColorLegend';
import connect from 'react-redux/es/connect/connect';
import * as ResourceStore from '../../store/ResourceStore';
import moment from 'moment';

const CustomTeamList = props => {
    const units = props.state[ResourceStore.reducerName].units;
    const bookings = props.state[ResourceStore.reducerName].bookings;
    const currentPadding = props.currentPadding;
    const key = props.key;


    const now = moment();
    const currentBookings = bookings.filter(x => now.isSameOrAfter(x.startTime) && now.isSameOrBefore(x.endTime));

    const teams = props.state.teamState.teams;
    if(teams.length < 1) return null;

    const addUnits = team => {
        team.units = currentBookings.reduce((result,b) => {
            if(b.teamId === team.id){
                result.push(units.find(u => u.id === b.unitId));
            }
            return result;
        }, []);
    };

    // const teamsToShow = props.teams;

    // teamsToShow.forEach(team => {
    //     team.team = teams.find(x => x.id === team.id);
    // });

    // // Vi har umiddelbart ikke brug for at tilføje units her - men hvis vi får brug for det, skal det gøres på hvert team
    // //addUnits(administration);
    // //addUnits(pladsen);

    // const showTeam = (team) => {
    //     return team.team && <Team key={team.team.id} team={team.team} width={team.width || 100/teamsToShow.length}/>;
    // };


    // return (
    //     <div key={key} className="team-list" style={{ paddingRight: `${currentPadding}%`}}>
    //         {teamsToShow.map(team => showTeam(team))}
    //     </div>
    // );


    const stab = teams.find(x => x.id === 125);
    const nytteJobberne = teams.find(x => x.id === 127);
    const vaerkstedet = teams.find(x => x.id === 128);
    const naturVandOgVej = teams.find(x => x.id === 126);
    const grønDriftOgIdraet = teams.find(x => x.id === 129);
   
    addUnits(stab);
    addUnits(nytteJobberne);
    addUnits(vaerkstedet);
    addUnits(naturVandOgVej);
    addUnits(grønDriftOgIdraet);

    return (
        <div className="team-list">
            {stab &&
                <Team
                    team={stab}
                    width={20}
                    subTeams={[]}

                />
            }
            {nytteJobberne &&
                <Team
                    team={nytteJobberne}
                    width={15}
                    subTeams={[]}

                />
            }
            {vaerkstedet &&
                <Team
                    team={vaerkstedet}
                    width={15}
                    subTeams={[]}

                />
            }
            {naturVandOgVej &&
                <Team
                    team={naturVandOgVej}
                    width={20}
                    subTeams={[]}
                />
            }
            {grønDriftOgIdraet &&
                <Team
                    team={grønDriftOgIdraet}
                    width={30}
                    subTeams={[]}
                />
            }
            <ColorLegend />
        </div>
    );

};

export default connect(
    state => ({state: state})
)(CustomTeamList);