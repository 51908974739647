import React, { Component } from 'react';
import Menu from './components/Menu';
import Calendar from './components/planning/Calendar';
import PlanForm from './components/planning/PlanForm';

import './css/App.css';

class Plan extends Component {

  constructor(props){
    super(props);
    this.personid = props.match.params.personId;
  }

  render() {

    return (
      <div className="App">
        <Menu />
        <Calendar />
        {/* {this.personid ? <PlanForm personid={this.personid}/> : ''} */}
      </div>
    );
  }
}

export default Plan;
