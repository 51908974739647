import React from 'react';
import Person from './Person';
import '../../css/Team.css';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import { render } from 'react-dom';

const addVerticalDivider = (index) => {
    return index > 0 && <div className="vertical-divider"/>
};

const Team = props => {
    const team = props.team;
    const members = team.members;
    var hideTeamLeader = "";

    var hideTeam = team.name === null ? " hidden" : "";

    const renderPersonComponents = (members) => {
        return members.map((member, i) => {
            const p = props.personState.persons[member.id];
            return <Person key={i} person={p} />
        });
    };

    let teamLeader;
    if(team.teamLeader){
        hideTeamLeader = team.teamLeader.name === null ? " hidden" : "";
        const p = props.personState.persons[team.teamLeader.id];
        teamLeader = <Person person={p}/>;
    }
    const style = {
        width: props.width + '%',
        height: props.height + '%',
        top: props.top + '%',
        marginBottom: props.hasOwnProperty('bottomMargin') && props.bottomMargin + "vw"
    };

    const innerStyle = {
        width: props.innerWidth + '%',
        height: props.innerHeight + '%',
    };

    const renderSubTeams = () => {
        if(!props.subTeams) return;

        return props.subTeams.map((x,i) => {
            return(
                <div key={i} className='subteam'>
                    <div className="divider" />
                    <h4 className="team-description">{x.name}</h4>
                    <div className="members">
                        {renderPersonComponents(x.members)}
                    </div>
                </div>
            )
        });
    };
    // subTeamsToShowVertically == [[team1, team2], [team5, team6]]
    // her skal team1 og 2 vises ved siden af hinanden - derefter 5 og 6 ved siden af hinananden
    const renderVerticalSubTeams = () => {
        if(!props.hasOwnProperty('subTeamsToShowVertically') || props.subTeamsToShowVertically.length == 0) return;

        const renderOneVerticalSubTeamGroup = (subteamGroup) => {

            const amountOfTeams = subteamGroup.length;
            const widthForEachSubTeam = amountOfTeams > 0 ? 100 / amountOfTeams : amountOfTeams;

            const subTeamStyling = {
                width: widthForEachSubTeam + "%"
            }

            return (
                <div className='vertical-wrapper' >
                    {   subteamGroup.map((x,i) => {
                        return(
                            <div key={i} className='subteam horizontally-align' style={subTeamStyling}>
                                <div className="divider"/>
                                <h4 className="team-description">{x.name}</h4>
                                <div className="members">
                                    {renderPersonComponents(x.members)}
                                </div>
                                {addVerticalDivider(i)}
                            </div>
                        )})
                    }
                </div>
            );

        }

        return props.subTeamsToShowVertically.map(renderOneVerticalSubTeamGroup);
    };
    
    return (
        <div className="team" style={style}>
            <div className="inner" style={innerStyle}>
                <h3 className={"team-name" + hideTeam}>{team.name}</h3>
                <div className={"divider" + hideTeam} />
                <h4 className={"team-description" + hideTeam}>{team.description}</h4>
                <div className={"teamleader" + hideTeam + hideTeamLeader}>{teamLeader}</div>
                <div className={"members" + hideTeam}>
                    {renderPersonComponents(members)}
                </div>
                {renderSubTeams()}
                {renderVerticalSubTeams()}
            </div>
        </div>
    );
};

Team.propTypes = {
    team: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    subTeams: PropTypes.array
};

export default connect(
    state => ({personState: state.personState}),
)(Team);
