import React from 'react';
import Team from './Team'
import './TeamList.css';
import ColorLegend from '../planning/ColorLegend';
import connect from 'react-redux/es/connect/connect';

const TeamList = props => {
    const teams = props.state.teamState.teams;
    const width = 100/teams.length;

    let tc = null;
    if(teams){
        tc = teams.map((team) => {
            return <Team key={team.id} team={team} width={width} />
        })
    }

    return (
        <div className="team-list">
            {tc}
            <ColorLegend />
        </div>
    );
};

export default connect(
    state => ({state: state})
)(TeamList);