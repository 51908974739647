import React, { Component } from 'react';
import './css/App.css';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import {actionCreators as routerActions} from './store/RouterActions';
import {actionCreators as winterActions} from './store/WinterDutyStore';
import {actionCreators as personActions} from './store/PersonStore';
import {actionCreators as plannedItemActions} from './store/PlannedItemStore';
import {actionCreators as teamActions} from './store/TeamStore';
import {actionCreators as slideActions} from './store/SlideStore';
import {actionCreators as tv2NewsActions} from './store/Tv2NewsStore';
import {actionCreators as lorryNewsActions} from './store/LorryNewsStore';
import * as GridStore from './store/GridStore';
import Menu from './components/Menu';
import TeamList from './components/organization/TeamList';
import moment from 'moment';
/*import DrNews from "./components/slides/DrNews";*/
import CustomTeamList from './components/organization/CustomTeamList';

import FreeText from "./components/freeText/FreeText";
import SlideShow from "./components/slides/SlideShow";
import Carousel from './components/carousel/Carousel';
import ColorLegend from './components/planning/ColorLegend';
import LorryNews from "./components/slides/LorryNews";
import logoImage from './images/customer_logo.png';



class Home extends Component {

    componentDidMount() {
        this.props.plannedItemActions.setInterval(moment().startOf('day'), moment().add(5).startOf('day'));
        this.props.plannedItemActions.getAllPlans();

        this.updateDates  = setInterval(() => {
            this.props.plannedItemActions.setInterval(moment().startOf('day'), moment().add(5).startOf('day'));
        }, 1000*180);
    }

    componentWillUnmount() {
        clearInterval(this.updateDates);
    }

    render() {
        return (
            <div className="App">
                <img style={{width: '15%', paddingTop: '3vh'}} src={logoImage}></img>
                <Menu/>
                <CustomTeamList/>
                
                <div className='bottom-wrapper'>
                    <div className='slide-and-news-wrapper-1'>
                        <div className='slide-wrapper slide-show'>
                             <SlideShow slideShowId={10030}/>
                        </div>
                    </div>
                    <div className='free-text-wrapper'>
                        <FreeText/>
                    </div>
                    <div className='slide-and-news-wrapper-2'>
                        <div className='slide-wrapper slide-show'>
                             <SlideShow slideShowId={10029}/>
                        </div>
                    </div>
                </div>

            </div>
        );
  }
}

export default connect(
    state => ({state: state}),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
        winterActions: bindActionCreators(winterActions, dispatch),
        personActions: bindActionCreators(personActions, dispatch),
        plannedItemActions: bindActionCreators(plannedItemActions, dispatch),
        teamActions: bindActionCreators(teamActions, dispatch),
        slideActions: bindActionCreators(slideActions, dispatch),
        tv2NewsActions: bindActionCreators(tv2NewsActions, dispatch),
        gridActions: bindActionCreators(GridStore.actionCreators, dispatch),
        lorryNewsActions: bindActionCreators(lorryNewsActions, dispatch),
    })
)(Home);
